<template>
    <div class="container-fluid main-color">
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">Add Handover</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->

        <place-holder v-if="loading"></place-holder>
        <div class="row" v-show="!loading">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form class="form-horizontal" v-if="!loading">
                            <div class="mb-3 d-flex">
                                <label class="me-3 col-form-label">Trainer </label>
                                <div class="col-2 col-xl-2" style="width: 200px">
                                    <v-select label="name" v-model="trainer" :options="trainers" :clearable="false"
                                        @option:selected="getModuleByTrainer($event)">
                                        <!-- :selectable="(option) =>
                                                !option.employee_id.toString().includes(current_user_id)" -->
                                    </v-select>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <table id="handover-datatable" class="table" v-if="!loading">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th style="width: 150px !important">Modules</th>
                                            <th style="width: 150px !important">Trainer From</th>
                                            <th style="width: 200px !important">Trainer To</th>
                                            <th>Issued Date</th>
                                            <th style="width: 150px !important">Remark</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="!loading">
                                        <tr v-for="(item, index) in handoverDatas" :key="index">
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ item.module_name }}</td>
                                            <td>
                                                <span v-for="(trainer, index) in item.trainer_from" :key="index">{{
                                                    trainer.name
                                                }},
                                                </span>
                                            </td>
                                            <td>
                                                <v-select multiple id="vue-selectCss" class="custom-selectCSS"
                                                    v-model="item.trainer_to" label="name" :options="trainersTo"
                                                    :disabled="item.handover_process_status == 1 ? true : false"
                                                    :clearable="false" data-bs-toggle="tooltip"
                                                    :title="item.handover_process_status == 1 ? 'You cannot choose trainer during handover process!' : ''">
                                                </v-select>
                                            </td>
                                            <td>{{ item.issued_date }}</td>
                                            <td>
                                                <input v-model="item.remark" type="text" id="remark" name="remark"
                                                    class="form-control" placeholder="Remark"
                                                    :disabled="item.handover_process_status == 1 ? true : false"
                                                    data-bs-toggle="tooltip"
                                                    :title="item.handover_process_status == 1 ? 'You cannot add remark during handover process!' : ''" />
                                            </td>
                                            <td>
                                                <span>
                                                    <a @click="deleteRow(index)" href="javascript:void(0)"
                                                        class="action-icon"><i class="fas fa-trash-alt mx-1"></i></a></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-12" v-if="!loading">
                                <div class="text-center mt-3 mb-3">
                                    <router-link :to="{ name: 'module-handover' }">
                                        <button type="button" class="btn w-sm btn-secondary me-5">
                                            Cancel
                                        </button>
                                    </router-link>
                                    <!-- <button type="button" class="btn w-sm btn-success waves-effect waves-light px-3" @click="
                                        currentRouteName === 'module-handover-update'
                                            ? updateModuleHandover()
                                            : createModuleHandover()
                                    ">
                                        Save
                                    </button> -->
                                    <button
                                        v-if="process_status != 0 || handoverDatas == [] || handoverDatas == null || handoverDatas == ''"
                                        type="button" class="btn w-sm btn-success waves-effect waves-light px-3"
                                        style="cursor: not-allowed;" data-bs-toggle="tooltip"
                                        title="You cannot save data during handover process!">
                                        Save
                                    </button>
                                    <button v-else :disabled="isLoading ? true : false" type="button"
                                        class="btn w-sm btn-success waves-effect waves-light px-3"
                                        @click="createModuleHandover()">
                                        <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        {{ isLoading == true ? 'Loading...' : 'Save' }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- end card -->
            </div>
        </div>

    </div>
</template>
  
<script>
//Datatable Modules
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";

import axios from "axios";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
// import { required } from "@vuelidate/validators";
import validationErrors from "../../mixins/validationErrors";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";

export default {
    setup() {
        const toast = useToast();
        return { v$: useVuelidate(), toast };
    },
    mixins: [validationErrors],
    data() {
        return {
            current_date: "",
            trainer: "",
            trainers: [],
            trainersTo: [],
            oldHandoverDatas: [],
            handoverDatas: [
                {
                    module_name: null,
                    trainer_from: null,
                    trainer_to: null,
                    issued_date: null,
                    remark: null,
                },
            ],
            parameterLists: [],
            process_status: "",
            current_user_id: this.$store.state.auth.user.employee_id,
            baseUrl: process.env.VUE_APP_BASE_URL,
            baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
            loading: false,
            isLoading: false
        };
    },
    validations() {
        return {
            // handoverDatas: {
            //     trainer_to: { required },
            // },
        };
    },
    methods: {
        getTrainers() {
            this.loading = true;
            this.$Progress.start();
            axios
                .get(`${this.baseUrl}admin/v1/trainers`)
                .then((response) => {
                    var trainerLists = response.data.data.map(function (val) {
                        //for active trainers
                        if (val.status == 1) {
                            return {
                                id: val.user.id,
                                employee_id: val.user.employee_id,
                                name: val.user.name,
                            };
                        }
                    });
                    this.trainersTo = trainerLists;
                    if (
                        this.$store.state.auth.user.roles[0] == "Training Head" || this.$store.state.auth.user.roles[0] == "Super Admin" || this.$store.state.auth.user.roles[0] == "Trainer") {
                        this.trainers = trainerLists;
                    } else {
                        trainerLists.map((item) => {
                            if (item.employee_id == this.current_user_id) {
                                this.trainers.push(item);
                            }
                        })
                    }
                    this.loading = false;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.toast.error("Not Found Trainer!");
                });
        },

        checkStatusValue(data) {
            let status = data.handover_process_status;
            if (status == 1) {
                return false;
            } else {
                return true;
            }
        },

        getOldHandoverDatas() {
            this.handoverDatas = this.oldHandoverDatas;
        },

        getModuleByTrainer(value) {
            let checkStatus = [];
            this.loading = true;
            this.$Progress.start();
            axios
                .get(`${this.baseUrl}admin/v1/trainer-modules/${value.employee_id}`)
                .then((response) => {
                    let datas = response.data.data.modules;
                    this.handoverDatas = [];
                    datas.forEach((data) => {
                        let status = this.checkStatusValue(data);
                        let lists = null;
                        // let trainerToLists = [];
                        // this.trainersTo.forEach((trainer) => {
                        //     trainerToLists.push(trainer.employee_id);
                        // });
                        checkStatus.push(status);
                        if (checkStatus.includes(true)) {
                            this.process_status = 0;
                        } else {
                            this.process_status = 1;
                        }

                        lists = {
                            module_id: data.id,
                            module_name: data.name,
                            trainer_from: data.trainers,
                            trainer_to: data.handover_detail ? data.handover_detail.trainer_to : null,
                            issued_date: this.current_date,
                            remark: data.handover_detail ? data.handover_detail.remark : null,
                            handover_process_status: data.handover_process_status
                        };
                        this.handoverDatas.push(lists);
                    });
                    this.loading = false;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.toast.error("Not Found Modules!");
                });
        },

        deleteRow(index) {
            this.handoverDatas.splice(index, 1);
        },

        getModuleGroupById() {
        },

        checkEmptyValue(array) {
            let handoverDatas = Object.values(array);
            if (handoverDatas.includes(null) || handoverDatas.includes("")) {
                return false;
            } else {
                return true;
            }
        },

        createModuleHandover() {
            let checkDatas = [];
            checkDatas.push(this.handoverDatas);
            this.handoverDatas.forEach((array) => {
                let data = this.checkEmptyValue(array);
                checkDatas.push(data);
            });
            if (checkDatas.includes(false)) {
                this.toast.error("Please Fill All Data!");
                return;
            }
            this.handoverDatas.forEach((data) => {
                var lists = {
                    module_id: data.module_id,
                    module_name: data.module_name,
                    trainer_from: data.trainer_from,
                    trainer_to: data.trainer_to,
                    issued_date: this.current_date,
                    remark: data.remark,
                    requested_by: this.$store.state.auth.user.employee_id,
                    status: "Requested",
                    handover_process_status: data.handover_process_status,
                };
                this.parameterLists.push(lists);
            });
            let data = {
                selected_trainer: this.trainer,
                issued_date: this.current_date,
                is_created: 1,
                modules: this.parameterLists.filter((item) => {
                    if (item.handover_process_status == 0) {
                        return item;
                    }
                })
            };
            this.isLoading = true;
            axios
                .post(`${this.baseUrl}admin/v1/save-module-handover`, data)
                .then(() => {
                    this.toast.success("Successfully Module Handovered!");
                    this.$router.push({ name: "module-handover" });
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    this.toast.error("Something Went Wrong!");
                    this.isLoading = false;
                });
        },

        updateModuleHandover() {

        },

    },
    created() {
        this.getOldHandoverDatas();

        if (this.currentRouteName == "module-handover-update") {
            this.getModuleGroupById();
        }

        this.getTrainers();
        this.current_date = moment().format("YYYY-MM-DD");
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
    },
    components: {
        vSelect,
    },
};
</script>
  
<style>
#vue-selectCss ul {
    position: relative !important;
}

.custom-selectCSS {
    --vs-dropdown-min-width: 160px;
    --vs-dropdown-max-height: 200px;
}
</style>

<style scoped>
.form-control:disabled,
.form-control[readonly] {
    background-color: #efefef;
    opacity: 1;
    cursor: not-allowed;
}
</style>
  